import { FC, ReactNode } from 'react';
import { Box, alpha, lighten, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Footer from 'src/components/Footer';
import Sidebar from './Sidebar';
import Header from './Header';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();

  return (
    <>
      <Toaster />

      <Box
        sx={{
          flex: 1,
          flexDirection: 'column',
          display: 'flex',
          height: '100%',
          '.MuiPageTitle-wrapper': {
            background: `${theme.sidebar.background}`,
            marginBottom: `${theme.spacing(4)}`,
            boxShadow: `${theme.sidebar.boxShadow}`
          }
        }}
      >
        <Header />

        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'flex',
            flex: 1,
            pt: `${theme.header.height}`
          }}
        >
          <Sidebar />

          <Box display="block" width="100%">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
