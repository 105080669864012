import {
  Box,
  Tooltip,
  Typography,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  ({ theme }) => `

        color: ${theme.palette.text.primary};
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        margin: 0 auto;
        @media (min-width: ${theme.breakpoints.values.md}px) {
      
        }
`
);

function Logo() {
  const theme = useTheme();
  return (
    <Box>
      <LogoWrapper to={'/'}>
        <Box display="flex" gap={2} alignItems={'center'}>
          <img width={30} src="/static/images/logo/logo.svg" />
          <Typography
            display={{ xs: 'none', md: 'block' }}
            color={theme.colors.primary.main}
            variant="h4"
          >
            Rate Corner
          </Typography>
        </Box>
      </LogoWrapper>
    </Box>
  );
}

export default Logo;
