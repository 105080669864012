import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Avatar,
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  Button,
  lighten,
  darken,
  Typography,
  Tooltip
} from '@mui/material';

import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/Logo';
import { UserContext } from 'src/contexts/UserContext';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.white[100]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar() {
  const { user } = useContext(UserContext);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          left: 0,
          top: 0,
          background:
            theme.palette.mode === 'dark'
              ? darken(theme.colors.alpha.black[100], 0.5)
              : alpha(lighten(theme.header.background, 0.1), 0.5),
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
        }}
      >
        <Scrollbar>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 4,
              mb: 2
            }}
          >
            <Avatar
              sx={{
                fontSize: `${theme.typography.pxToRem(24)}`,
                bgcolor: theme.colors.primary.lighter,
                color: theme.colors.alpha.black[100],
                width: 68,
                height: 68,
                mb: 2
              }}
            >
              {user?.nickname?.substring(0, 2) || 'A'}
            </Avatar>
            <Typography
              sx={{
                color: theme.colors.alpha.black[100]
              }}
            >
              {user.email || 'Please Login'}
            </Typography>
          </Box>
          <SidebarMenu />
        </Scrollbar>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background: theme.colors.alpha.white[100]
          }}
        >
          <Scrollbar>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 4,
                mb: 2
              }}
            >
              <Avatar
                sx={{
                  fontSize: `${theme.typography.pxToRem(24)}`,
                  bgcolor: theme.colors.primary.lighter,
                  color: theme.colors.alpha.black[100],
                  width: 68,
                  height: 68,
                  mb: 2
                }}
              >
                {user?.nickname?.substring(0, 2) || 'A'}
              </Avatar>
              <Typography
                sx={{
                  color: theme.colors.alpha.black[100]
                }}
              >
                {user.email || 'Please Login'}
              </Typography>
            </Box>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
