import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
  exp: number;
}

export function isTokenExpired(token: string): boolean {
  if (token.trim() === '') return true;
  const decodedToken = jwtDecode(token) as DecodedToken;
  const expirationTime = decodedToken.exp * 1000;
  const currentTime = Date.now();
  const isExpired = expirationTime < currentTime;
  if (isExpired) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }
  return isExpired;
}
