import { useContext } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  Button
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';
import Logo from 'src/components/Logo';
import { UserContext } from 'src/contexts/UserContext';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        position: fixed;
        z-index: 6;
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        justify-content:flex-end;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);

        width: 100%;
        @media (min-width: ${theme.breakpoints.values.md}px) {
          padding: ${theme.spacing(0, 2)};
          justify-content: space-between;
          alignItems:center;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { user, isLogin, setUser } = useContext(UserContext);
  const theme = useTheme();

  return (
    <HeaderWrapper
      display="flex"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`
      }}
    >
      <Box display="flex" gap={2} alignItems="center" flex={1}>
        <Logo />
        <HeaderMenu />
      </Box>

      {isLogin ? (
        <Box display="flex" alignItems="center">
          <HeaderButtons />
          <HeaderUserbox />
          <Box
            component="span"
            sx={{
              display: { lg: 'none', xs: 'inline-block' }
            }}
          >
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleSidebar}>
                {!sidebarToggle ? (
                  <MenuTwoToneIcon fontSize="small" />
                ) : (
                  <CloseTwoToneIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <Box display="flex" gap={2} alignItems="center">
          <Button sx={{ flexShrink: 0 }} href="/login" variant="contained">
            Login
          </Button>
          <Button sx={{ flexShrink: 0 }} href="/register" variant="outlined">
            Join
          </Button>
        </Box>
      )}
    </HeaderWrapper>
  );
}

export default Header;
