import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Header from '../SidebarLayout/Header';
import Logo from 'src/components/Logo';
import { Toaster } from 'react-hot-toast';

import {
  Box,
  Avatar,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  Button,
  lighten,
  darken,
  Typography,
  Tooltip
} from '@mui/material';
import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import SidebarMenu from '../SidebarLayout/Sidebar/SidebarMenu';
import { UserContext } from 'src/contexts/UserContext';

interface BaseLayoutProps {
  children?: ReactNode;
}
const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.white[100]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const { user } = useContext(UserContext);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <Toaster />
      <Box
        sx={{
          flex: 1,
          height: '100%',
          display: 'flex'
        }}
      >
        <Header />
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flex: 1,
            mt: `${theme.header.height}`
          }}
        >
          <Box display="block" width="100%">
            {children || <Outlet />}
          </Box>
        </Box>
      </Box>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background: theme.colors.alpha.white[100]
          }}
        >
          <Scrollbar>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 4,
                mb: 2
              }}
            >
              <Avatar
                sx={{
                  fontSize: `${theme.typography.pxToRem(24)}`,
                  bgcolor: theme.colors.primary.lighter,
                  color: theme.colors.alpha.black[100],
                  width: 68,
                  height: 68,
                  mb: 2
                }}
              >
                {user?.nickname?.substring(0, 2) || 'A'}
              </Avatar>
              <Typography
                sx={{
                  color: theme.colors.alpha.black[100]
                }}
              >
                {user.email || 'Please Login'}
              </Typography>
            </Box>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
