import { useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from React Router
import { NavLink } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  useTheme
} from '@mui/material';
import { deepOrange, deepPurple } from '@mui/material/colors';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { UserContext } from 'src/contexts/UserContext';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
  
        background: ${theme.colors.alpha.white[100]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.text.secondary}
`
);

function HeaderUserbox() {
  const { user, signout } = useContext(UserContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton ref={ref} onClick={handleOpen}>
        <Avatar sx={{ bgcolor: deepOrange[500] }}>
          {user?.nickname?.substring(0, 2) || 'A'}
        </Avatar>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <UserBoxText>
            <UserBoxDescription variant="body2">
              {user.email}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>

        <List sx={{ p: 1 }} component="nav">
          <ListItem
            sx={{
              gap: 2,
              color: `${theme.colors.alpha.black[70]} !important`,
              '&:hover, &.Mui-selected': {
                color: `${theme.palette.primary.main} !important`
              }
            }}
            to="/personal/profile/details"
            component={NavLink}
          >
            <PersonIcon fontSize="small" />
            <Typography>Profile</Typography>
          </ListItem>
          <ListItem
            sx={{
              gap: 2,
              color: `${theme.colors.alpha.black[70]} !important`,
              '&:hover, &.Mui-selected': {
                color: `${theme.palette.primary.main} !important`
              }
            }}
            to="/personal/myRating/ratingHistory"
            component={NavLink}
          >
            <EditIcon fontSize="small" />
            <Typography>My Ratings</Typography>
          </ListItem>
          <ListItem
            sx={{
              gap: 2,
              color: `${theme.colors.alpha.black[70]} !important`,
              '&:hover, &.Mui-selected': {
                color: `${theme.palette.primary.main} !important`
              }
            }}
            to="/personal/myFavorite/myFavorite"
            component={NavLink}
          >
            <StarOutlineOutlinedIcon fontSize="small" />
            <Typography>My Favorites</Typography>
          </ListItem>
          <ListItem
            sx={{
              gap: 2,
              color: `${theme.colors.alpha.black[70]} !important`,
              '&:hover, &.Mui-selected': {
                color: `${theme.palette.primary.main} !important`
              }
            }}
            onClick={signout}
            to="/login"
            component={NavLink}
          >
            <LogoutIcon fontSize="small" />
            <Typography>Sign out</Typography>
          </ListItem>
        </List>
        <Divider />
      </Popover>
    </>
  );
}

export default HeaderUserbox;
