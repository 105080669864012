import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { Route, RouteObject } from 'react-router';
import { isTokenExpired } from 'src/utils/checkToken';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const withAuth = (Component) => {
  const AuthRoute = () => {
    const token = localStorage.getItem('token');
    const isLoginRequired = isTokenExpired(token || '');
    if (isLoginRequired) return <Navigate to="/login" replace />;
    return Component;
  };
  return <AuthRoute />;
};
// Pages
const Overview = Loader(lazy(() => import('src/content/overview')));
const Login = Loader(lazy(() => import('src/content/login')));
const Register = Loader(lazy(() => import('src/content/register')));
const VerifyPending = Loader(lazy(() => import('src/content/verifyPending')));
const VerifySuccess = Loader(lazy(() => import('src/content/verifySuccess')));
const ResetPassword = Loader(lazy(() => import('src/content/resetPassword')));
//Rating
const RatingAdd = Loader(lazy(() => import('src/content/rating/add')));
const RatingDetail = Loader(lazy(() => import('src/content/rating/detail')));
const RatingEdit = Loader(lazy(() => import('src/content/rating/edit')));
//Course
const CourseDetail = Loader(lazy(() => import('src/content/course/detail')));
//Profile
const ProfileDetail = Loader(
  lazy(() => import('src/content/profile/profileDetail'))
);
const ChangePassword = Loader(
  lazy(() => import('src/content/profile/changePassword'))
);
const MyRating = Loader(lazy(() => import('src/content/profile/myRating')));
const MyFavorite = Loader(lazy(() => import('src/content/profile/myFavorite')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'resetPassword',
        element: <ResetPassword />
      },
      {
        path: 'verifyPending',
        element: <VerifyPending />
      },
      {
        path: 'verifyMail',
        element: <VerifySuccess />
      },
      {
        path: 'rating',
        children: [
          {
            path: 'add',
            element: withAuth(<RatingAdd />)
          },
          {
            path: 'edit/:id',
            element: withAuth(<RatingEdit />)
          },
          {
            path: 'detail/:id',
            element: <RatingDetail />
          }
        ]
      },
      {
        path: 'course',
        children: [
          {
            path: 'detail/:id',
            element: <CourseDetail />
          }
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'personal',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="profile" replace />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: withAuth(<ProfileDetail />)
          }
        ]
      },
      {
        path: 'changePassword',
        children: [
          {
            path: '',
            element: <Navigate to="changePassword" replace />
          },
          {
            path: 'changePassword',
            element: withAuth(<ChangePassword />)
          }
        ]
      },
      {
        path: 'myRating',
        children: [
          {
            path: '',
            element: <Navigate to="ratingHistory" replace />
          },
          {
            path: 'ratingHistory',
            element: withAuth(<MyRating />)
          }
        ]
      },
      {
        path: 'myFavorite',
        children: [
          {
            path: '',
            element: <Navigate to="myFavorite" replace />
          },
          {
            path: 'myFavorite',
            element: withAuth(<MyFavorite />)
          }
        ]
      }
    ]
  }
];

export default routes;
