import { Box, List, ListItem, ListItemText, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';

function HeaderMenu() {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'block'
          }
        }}
      >
        <List disablePadding component={Box} display="flex">
          {/* <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            component={NavLink}
            to="/"
          >
            <ListItemText
              sx={{
                color: `${theme.colors.alpha.black[70]} !important`,
                '&:hover, &.Mui-selected': {
                  color: `${theme.palette.primary.main} !important`
                }
              }}
              primaryTypographyProps={{ noWrap: true }}
              primary="Home"
            />
          </ListItem> */}
        </List>
      </Box>
    </>
  );
}

export default HeaderMenu;
