import React, { FC, useState, createContext, useEffect } from 'react';

type UserType = {
  email: string;
  id: number;
  name: string;
  nickname: string;
};

type UserContextType = {
  user: UserType;
  isLogin: any;
  signout: () => void;
  setUser: (newUser: UserType) => void;
  setIsLogin: (value: boolean) => void;
};
export const UserContext = createContext<UserContextType>(
  {} as UserContextType
);

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<UserType>({
    email: '',
    id: 0,
    name: '',
    nickname: ''
  });
  const [isLogin, setIsLogin] = useState(false);

  const signout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setUser({
      email: '',
      id: 0,
      name: '',
      nickname: ''
    });
    setIsLogin(false);
  };
  useEffect(() => {
    const userDataString = localStorage.getItem('user');
    if (userDataString) {
      const userObject = JSON.parse(userDataString);
      setUser(userObject);
      setIsLogin(true);
      console.log('userCOntext', userObject);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{ signout, setIsLogin, isLogin, user, setUser }}
    >
      {children}
    </UserContext.Provider>
  );
};
